import { GridItem, LayoutGrid } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import {
  type FocusEvent,
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
} from 'react';

import Motion from '@/app/components/Motion';

import HomeHeroContent from './HomeHeroContent';
import type {
  HomeHeroContentButtonProps,
  HomeHeroContentCopyProps,
} from './types';

export interface HomeHeroProps extends HTMLAttributes<HTMLElement> {
  readonly BottomSlot?: ReactNode;
  readonly heroContent: {
    button: HomeHeroContentButtonProps;
    copy?: HomeHeroContentCopyProps;
    icon?: ReactNode | undefined;
  };
  readonly onFocus?: (event: FocusEvent) => void;
  readonly overlayPosition?: 'fixed' | 'absolute' | undefined;
  readonly TitleSlot?: ReactNode;
  readonly TopSlot?: ReactNode;
  readonly titleSlotOptions?: {
    className?: string;
  };
}

const HomeHero = forwardRef(function HomeHero(
  {
    BottomSlot,
    heroContent,
    overlayPosition,
    titleSlotOptions,
    TitleSlot,
    TopSlot,
    ...props
  }: HomeHeroProps,
  ref: Ref<HTMLElement>,
) {
  return (
    <section
      className="h-svh bg-sandstone-200 lg:pb-4"
      data-cy="home-hero"
      ref={ref}
      {...props}
    >
      <LayoutGrid className="h-full">
        <GridItem
          className="h-full overflow-hidden lg:mx-5 lg:rounded-b-xl"
          colEnd={{ sm: 'full' }}
          colStart={{ sm: 'full' }}
          rowEnd={{ sm: 2 }}
          rowStart={{ sm: 1 }}
        >
          {TopSlot}
        </GridItem>
        <GridItem
          className="z-20 clip-polygon md:relative lg:mx-5"
          colEnd={{ sm: 'full' }}
          colStart={{ sm: 'full' }}
          rowEnd={{ sm: 2 }}
          rowStart={{ sm: 1 }}
        >
          <div
            className={cx(
              'top-0 left-0 h-svh w-full lg:-top-12 lg:bottom-0 lg:h-auto',
              overlayPosition,
            )}
            data-cy="home-hero-overlay"
          >
            <LayoutGrid
              className={cx(
                'h-full content-between lg:px-5 lg:pb-4',
                titleSlotOptions?.className,
              )}
              rowGap={0}
            >
              <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main' }}>
                <Motion
                  initial={{
                    opacity: 0,
                    translateY: 25,
                    filter: 'blur(5px)',
                  }}
                  animate={{ opacity: 1, translateY: 0, filter: 'blur(0)' }}
                  transition={{ duration: 1.25, type: 'tween', delay: 1 }}
                >
                  {TitleSlot}
                </Motion>
              </GridItem>
              <GridItem
                className="hidden md:block"
                colEnd={{ md: 10, lg: 10, xl: 8 }}
                colStart={{ md: 'main', lg: 'main', xl: 'main' }}
              >
                <Motion
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2, type: 'tween', delay: 1.25 }}
                >
                  <HomeHeroContent {...heroContent} />
                </Motion>
              </GridItem>
              <GridItem
                className="flex flex-col items-center self-end"
                colEnd={{ sm: 'main', md: 'main', lg: 'main', xl: 'main' }}
                colStart={{ sm: 'main', md: 15, lg: 15, xl: 18 }}
              >
                <Motion
                  className="w-full md:hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2, type: 'tween', delay: 1.25 }}
                >
                  <HomeHeroContent {...heroContent} />
                </Motion>
                {BottomSlot}
              </GridItem>
            </LayoutGrid>
          </div>
        </GridItem>
      </LayoutGrid>
    </section>
  );
});

export default HomeHero;
